// Migrated
<template lang="pug">
.crown-container.d-flex.flex-column.justify-content-center.align-content-center.position-relative(
  :class="`text-${color} ${getSize}`"
)
  i-crown.position-absolute(
    filled
    :font-controlled="false"
    width="100%"
    :class="{ 'crown-image-shadow': shadow }"
  )
  .pb-2.position-relative.px-3(
    :class="{ 'crown-text-shadow': shadow }"
  )
    span.text-center.d-block(:data-i18n="smallTitleKey") {{ $t(smallTitleKey) }}
    h4.text-uppercase.font-weight-semibold.text-break.text-center.mt-n1(:data-i18n="mainTitleKey") {{ $t(mainTitleKey) }}
</template>

<script>
export default defineNuxtComponent({
  props: {
    smallTitleKey: {
      type: String,
      default: 'crownSmallTitle'
    },

    mainTitleKey: {
      type: String,
      default: 'crownMainTitle'
    },

    size: {
      type: String,
      default: 'sm'
    },

    shadow: {
      type: Boolean,
      default: true
    },

    color: {
      type: String,
      default: 'white'
    }
  },

  computed: {
    getSize () {
      switch (this.size) {
        case 'lg':
          return 'crown-lg'
        default:
          return 'crown-sm'
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$width-sm: 160px;
$width-lg: 200px;
$base-font-size-sm: .8rem;
$base-font-size-lg: 1rem;

.crown {
  &-sm {
    width: $width-sm;
    min-width: $width-sm;
    height: $width-sm * 0.8;

    span {
      font-size: $base-font-size-sm;
    }
    h4 {
      font-size: $base-font-size-sm * 1.37;
    }

    @media (max-width: $sm) {
      $modifier: .9;

      width: $width-sm * $modifier;
      min-width: $width-sm * $modifier;
      height: $width-sm * 0.8 * $modifier;

      span {
        font-size: $base-font-size-sm * $modifier;
      }

      h4 {
        font-size: $base-font-size-sm * $modifier * 1.37;
      }
    }
  }

  &-lg {
    width: $width-lg;
    min-width: $width-lg;
    height: $width-lg * 0.8;

    span {
      font-size: $base-font-size-lg;
    }
    h4 {
      font-size: $base-font-size-lg * 1.37;
    }
  }

  &-container {

    & > div {
      padding-top: 10px !important;
      padding-right: 3px;
    }

    h4 {
      margin-top: 0 !important;
    }
  }

  &-image-shadow {
    filter: drop-shadow(2px 2px 3px rgba(0,0,0,.35))
  }

  &-text-shadow {
    * {
      filter: drop-shadow(2px 2px 2px rgba(0,0,0,.4));
    }
  }
}
</style>
